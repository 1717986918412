const addTabs = function(that, name, id, pathName, dataInfo) {
  that.$store.commit('settings/pushGroupId', {
    name: name,
    id: id,
    pathName: pathName,
    dataInfo: dataInfo
  })
  // let path = '/group/detail/' + id
  // that.$router.push({
  //   path: path
  // })
}
export { addTabs }
