<template>
  <div class="app-container">
    <div class="left_box">
      <GroupCard v-if="groupInfo !== null" :groupInfo="groupInfo" />
      <div class="club_box">
        成员(
        <span>{{ total }}</span>
        )
        <el-button
          class="btn"
          size="small"
          type="primary"
          @click="groupResetFun"
        >
          重置
        </el-button>
      </div>
      <div
        class="bottom_box"
        v-infinite-scroll="scrollLoad"
        :infinite-scroll-disabled="disabled"
        :infinite-scroll-distance="30"
        v-loading="loading"
        id="group-user-list"
      >
        <BusinessCard
          ref="BusinessCardRef"
          :memberList="memberList"
          @reciverBusiness="reciverBusiness"
        />
        <p v-if="!noMore && memberList.length > 0" class="loading-text">
          加载中...
        </p>
        <p v-if="noMore && memberList.length > 0" class="loading-text">
          没有更多了
        </p>
        <div class="content-no" v-if="memberList.length == 0">
          暂无数据
        </div>
      </div>
    </div>

    <div class="center_box" id="right_box" v-loading="textLoading">
      <p v-if="messageList.length > 0 && !noMore2" class="loading-text">
        加载中...
      </p>
      <p v-if="messageList.length > 0 && noMore2" class="loading-text">
        没有更多了
      </p>

      <SingleMessage
        v-for="(item, i) in messageList"
        :key="item.id"
        :singleInfo="item"
        :msgId="msgId"
        @updateFun="submitFun"
      />
      <div class="content-no" v-if="messageList.length == 0">
        暂无数据
      </div>
    </div>
    <div class="right_box">
      <searchRight @submitFun="submitFun" ref="searchRightRef" />
    </div>
  </div>
</template>

<script>
import { addTabs } from '@/assets/js/addTabs'
import {
  getSingleGroupInfo,
  getMembers,
  messagesApi,
  messages1_Api,
  messages2_Api,
  messages3_Api
} from '@/api/whatsApp'
import GroupCard from './components/GroupCard.vue'
import BusinessCard from './components/BusinessCard.vue'
import SingleMessage from '../search/componentens/SingleMessage.vue'
import { scrollTopFun } from '@/plugins/fun'
import { cacheMixin } from '@/mixins/mixin'
import { requestMixin } from '@/mixins/search'
export default {
  name: 'GroupDetail',
  mixins: [cacheMixin, requestMixin],
  components: {
    GroupCard,
    BusinessCard,
    SingleMessage
  },
  data() {
    return {
      groupInfo: null,
      memberList: [],
      messageList: [],
      // 成员
      total: 0,
      loading: false,
      page_index: 1,
      page_size: 20,
      // 消息
      pageIndex: 1,
      pageSize: 20,
      loading2: false,
      total2: 0,
      authorId: null,
      textLoading: false,
      // 滚动
      disabled: false,
      noMore: false,
      disabled2: false,
      noMore2: false,
      // 消息id
      msgId: null,
      // 时间
      send_time: '',
      leftTop: 0,
      rightTop: 0,
      //
      scrollType: true
    }
  },
  created() {
    let groupId = this.$route.params.groupId
    let msgId = this.$route.query.msgId
    this.msgId = msgId
    let send_time = this.$route.query.send_time
    this.send_time = send_time
    this.disabled = true
    this.$nextTick(() => {
      if (groupId) {
        this.getGroupInfo(groupId).then(() => {
          // this.disabled = true
          this.getMembersList()
          this.disabled2 = true
          if (msgId && send_time) {
            this.msgId = msgId
            this.getMessageListInfo('-send_time', send_time).then(() => {
              this.getMessageListInfo('send_time', '', send_time).then(() => {
                window.location.href = `#${msgId}`
              })
            })
          } else {
            this.noMore2 = true
            this.getMessageListInfo('-send_time').then(() => {
              let obj = this.messageList[this.messageList.length - 1]
              if (obj && obj.id) {
                window.location.href = `#${obj.id}`
              }
            })
          }
        })
      }
    })
  },

  mounted() {
    this.scrollFun()
  },
  activated() {
    // 左侧
    scrollTopFun('group-user-list', 'set', this.leftTop)
    // 右侧消息
    scrollTopFun('right_box', 'set', this.rightTop)
  },
  deactivated() {
    // 左侧
    this.leftTop = scrollTopFun('group-user-list', 'get')
    // 右侧消息
    this.rightTop = scrollTopFun('right_box', 'get')
  },
  beforeDestroy() {
    // 组件销毁前移除滚动事件监听器

    window.removeEventListener('scroll', this.scrollFun)
  },
  methods: {
    // 滚动事件
    scrollFun() {
      let scrollableDiv = document.getElementById('right_box')
      let scrollTop = scrollableDiv.scrollTop
      let lastScrollTop = scrollableDiv.scrollTop
      let that = this
      scrollableDiv.addEventListener('scroll', function() {
        if (!that.scrollType) return
        const scrollTop = scrollableDiv.scrollTop
        const scrollDirection = scrollTop > lastScrollTop ? 'down' : 'up'

        if (scrollDirection == 'up') {
          let obj = that.messageList[0]
          let time = ''
          if (obj) {
            time = obj.send_time
          }
          that.getMessageListInfo('-send_time', time).then(arr => {
            if (arr.length < that.pageSize) {
              that.$message.warning('数据到顶了~')
            } else {
              window.location.href = `#${obj.id}`
            }
          })
        }
      })
    },
    getGroupInfo(id) {
      return new Promise((resolve, reject) => {
        const that = this
        getSingleGroupInfo(id)
          .then(res => {
            this.groupInfo = Object.assign({}, res)
            let name = res.name ? res.name : res.id
            addTabs(that, name, res.id, '/group/detail/', {
              msgId: this.msgId,
              send_time: this.send_time
            })
            resolve()
          })
          .catch(() => {
            this.loading = false
            this.$message.warning('无法获取该群组详情！')
            setTimeout(() => {
              this.$router.go(-1)
            }, 1500)
          })
      })
    },
    // 获得群成员
    async getMembersList() {
      try {
        const data = {
          group: this.$route.params.groupId,
          page_index: this.page_index,
          page_size: this.page_size
        }
        this.disabled = true
        this.loading = true
        const res = await getMembers(data)

        if (res.items.length < res.page_size) {
          this.noMore = true
        } else {
          this.page_index = this.page_index + 1
          this.disabled = false
        }
        this.memberList = [...this.memberList, ...res.items]
        this.total = res.count
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$message.error(error)
      }
    },
    scrollLoad() {
      this.getMembersList()
    },
    // 获得消息
    messageLoad() {
      let obj = this.messageList[this.messageList.length - 1]
      let time = ''
      if (obj) {
        time = obj.send_time
      }
      this.getMessageListInfo('send_time', '', time)
    },
    getMessageListInfo(ordering, send_time_lte, send_time_gte) {
      return new Promise((resolve, reject) => {
        const data = {
          ordering: ordering || 'send_time',
          group: this.$route.params.groupId,
          // page_index: this.pageIndex,
          page_size: this.pageSize,
          author: this.authorId,
          send_time__lte: send_time_lte || '',
          send_time__gte: send_time_gte || '',
          search: this.$refs.searchRightRef.queryForm.search
        }
        let search_obj = {}
        for (let i in data) {
          if (data[i]) {
            search_obj[i] = data[i]
          }
        }
        this.textLoading = true
        this.disabled2 = true
        if (this.$refs.searchRightRef.queryForm.radio == 1) {
          messages1_Api(search_obj).then(res => {
            if (res.items.length < res.page_size) {
              this.noMore2 = true
            } else {
              this.pageIndex = this.pageIndex + 1
              this.disabled2 = false
            }
            // this.messageList = [...this.messageList, ...res.items]

            if (ordering == '-send_time') {
              res.items.forEach(item => {
                if (
                  !this.messageList.some(ele => {
                    return item.id == ele.id
                  })
                ) {
                  this.messageList.unshift(item)
                }
              })
            } else {
              res.items.forEach(item => {
                if (
                  !this.messageList.some(ele => {
                    return item.id == ele.id
                  })
                ) {
                  this.messageList.push(item)
                }
              })
            }

            this.total2 = res.count
            this.textLoading = false
            resolve(res.items)
          })
        } else if (this.$refs.searchRightRef.queryForm.radio == 2) {
          messages2_Api(search_obj).then(res => {
            if (res.items.length < res.page_size) {
              this.noMore2 = true
            } else {
              this.pageIndex = this.pageIndex + 1
              this.disabled2 = false
            }
            // this.messageList = [...this.messageList, ...res.items]

            if (ordering == '-send_time') {
              res.items.forEach(item => {
                if (
                  !this.messageList.some(ele => {
                    return item.id == ele.id
                  })
                ) {
                  this.messageList.unshift(item)
                }
              })
            } else {
              res.items.forEach(item => {
                if (
                  !this.messageList.some(ele => {
                    return item.id == ele.id
                  })
                ) {
                  this.messageList.push(item)
                }
              })
            }

            this.total2 = res.count
            this.textLoading = false
            resolve(res.items)
          })
        } else if (this.$refs.searchRightRef.queryForm.radio == 3) {
          messages3_Api(search_obj).then(res => {
            if (res.items.length < res.page_size) {
              this.noMore2 = true
            } else {
              this.pageIndex = this.pageIndex + 1
              this.disabled2 = false
            }
            // this.messageList = [...this.messageList, ...res.items]

            if (ordering == '-send_time') {
              res.items.forEach(item => {
                if (
                  !this.messageList.some(ele => {
                    return item.id == ele.id
                  })
                ) {
                  this.messageList.unshift(item)
                }
              })
            } else {
              res.items.forEach(item => {
                if (
                  !this.messageList.some(ele => {
                    return item.id == ele.id
                  })
                ) {
                  this.messageList.push(item)
                }
              })
            }

            this.total2 = res.count
            this.textLoading = false
            resolve(res.items)
          })
        }
      })
    },
    // 群成员消息筛选
    reciverBusiness(val) {
      if (val) {
        this.pageIndex = 1
        this.messageList = []
        this.authorId = val
        this.getMessageListInfo()
      }
    },
    //
    groupResetFun() {
      // this.scrollType = false
      // this.pageIndex = 1
      // this.messageList = []
      this.authorId = ''
      this.$refs.BusinessCardRef.selectId = ''
      // this.disabled2 = true
      // this.noMore2 = false
      // this.rightTop = 0
      this.submitFun()
    },
    // 检索
    submitFun() {
      this.scrollType = false
      this.pageIndex = 1
      this.messageList = []
      this.disabled2 = true
      this.noMore2 = false
      this.rightTop = 0

      if (this.msgId && this.send_time) {
        this.getMessageListInfo('-send_time', this.send_time).then(() => {
          this.getMessageListInfo('send_time', '', this.send_time).then(() => {
            window.location.href = `#${this.msgId}`
          })
        })
      } else {
        this.getMessageListInfo('-send_time').then(() => {
          this.scrollType = true
          let obj = this.messageList[this.messageList.length - 1]
          if (obj && obj.id) {
            window.location.href = `#${obj.id}`
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 70px);
  .left_box {
    width: 300px;
    height: 100%;
    padding: 10px;
    overflow: hidden;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 0px 0 #d8d9d9;
    border-radius: 5px;

    .club_box {
      height: 38px;
      background: #f2f4f8;
      line-height: 38px;
      padding-left: 10px;
      display: flex;
      align-items: center;
      .btn {
        height: 30px;
        margin-left: 20px;
      }
      span {
        color: #ff5400;
      }
    }
    .bottom_box {
      height: calc(50% - 40px);
      padding: 10px 0;
      overflow-y: auto;
      position: relative;
    }
  }

  .center_box {
    width: calc(100% - 620px);
    height: 100%;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 0px 0 #d8d9d9;
    border-radius: 5px;
    padding: 10px;
    overflow: auto;
    position: relative;
    .nomore_box {
      text-align: center;
      font-size: 14px;
      color: #949698;
    }
  }
  .right_box {
    width: 300px;
    height: 100%;
  }
  .loading-text {
    font-size: 12px;
    color: #949698;
    text-align: center;
  }
  .content-no {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #949698;
  }
}
</style>
