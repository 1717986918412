<template>
  <div class="group-components">
    <div class="top_one">
      <img
        style="width:40px;height: 40px;"
        v-if="groupInfo.avatar !== null"
        src=""
        alt=""
      />
      <img
        v-else
        style="width:40px;height: 40px;"
        src="@/assets/imgs/mrtx.png"
        alt=""
      />
      <div>
        <div>{{ strFun(groupInfo.name) }}</div>
      </div>
      <el-popover
        placement="bottom"
        width="120"
        trigger="click"
        popper-class="group-page-popover"
      >
        <div class="group-detail-popover-box">
          <div class="more-item" @click="markFun">
            <img :src="require('@/assets/imgs/mark.png')" alt="" />
            <span>打标记</span>
          </div>

          <div class="more-item" @click="exportFileForGroup(groupInfo.id)">
            <img :src="require('@/assets/imgs/down.png')" alt="" />
            <span>导 出</span>
          </div>
        </div>
        <img
          width="25px"
          height="25px"
          slot="reference"
          src="../../../assets/imgs/more.png"
          alt=""
        />
      </el-popover>
    </div>
    <div class="top_two">
      <el-descriptions
        :column="1"
        :labelStyle="{
          width: '60px',
          textAlign: 'right',
          color: '#060606'
        }"
      >
        <el-descriptions-item label="ID">
          {{ groupInfo.id }}
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">
          {{ groupInfo.create_time }}
        </el-descriptions-item>

        <el-descriptions-item label="简介">
          {{ groupInfo.desc }}
        </el-descriptions-item>

        <el-descriptions-item label="标记">
          <div>
            <el-tag
              style="margin-bottom: 5px;margin-right: 2px;"
              v-for="item in tagList"
              :key="item.id"
              size="medium"
              :closable="true"
              @close="closeTagFun(item.tag)"
            >
              {{ item.tag.tag }}
            </el-tag>
            <tagComponents size="mini" @updateTagFun="updateTagFun" />
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <!-- 标签弹框 -->
    <el-dialog
      title="添加标签"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <LabelForm
        ref="LabelFormRef"
        @reciverLabel="reciverLabel"
        v-if="dialogVisible"
        @updateFun="labelFormUpdateFun"
        tagType="group"
        :singleInfo="groupInfo"
      />
    </el-dialog>
  </div>
</template>

<script>
import { getGroupTagList, addTagForGroup } from '@/api/tagged'
import { delGroupApi, addGroupApi } from '@/api/tag'
import LabelForm from '@/components/LabelForm.vue'
export default {
  name: 'GroupCard',
  props: {
    groupInfo: {
      type: Object,
      default: {}
    }
  },
  components: {
    LabelForm
  },
  data() {
    return {
      tagList: [],
      dialogVisible: false
    }
  },
  created() {
    // 获取群组标记
    this.getTagList()
  },
  methods: {
    async getTagList() {
      const data = {
        group: this.groupInfo.id
      }
      this.tagList = []
      const res = await getGroupTagList(data)
      this.tagList = res.items
    },

    // 导出群组数据
    exportFileForGroup(id) {
      const herf =
        process.env.VUE_APP_BASE_API + `/whatsapp/groups/${id}:export_excel`
      window.open(herf)
    },
    // 群组打标签开始
    async sendResult(val) {
      const data = {
        ids: val.tags
      }
      await addTagForGroup(this.groupInfo.id, data)
      this.dialogVisible = val.flag
      this.getTagList()
    },
    // 收到选中的标签
    reciverLabel(val) {
      // 收到值发送请求
      if (val.tags.length !== 0) {
        this.sendResult(val)
      } else {
        // 否则关闭弹框
        this.dialogVisible = val.flag
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 打标记
    markFun() {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.LabelFormRef.ruleForm.tags = this.tagList.map(item => {
          return item.tag.id
        })
      })
    },
    strFun(str) {
      if (!str) return ''
      if (str.length > 14) {
        return str.slice(0, 14) + '...'
      } else {
        return str
      }
    },
    labelFormUpdateFun() {
      this.dialogVisible = false
      this.getTagList()
    },
    // 删除tag标签
    closeTagFun(row) {
      this.$confirm('确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delGroupApi(this.groupInfo.id, row.id).then(res => {
            this.$message({
              type: 'success',
              message: '标签已删除！'
            })
            this.getTagList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //
    updateTagFun(res) {
      addGroupApi({
        tag: res.id,
        group: this.groupInfo.id
      })
        .then(res => {
          this.$message({
            type: 'success',
            message: '群组已标记！'
          })
          this.getTagList()
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: '群组标记失败！'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.group-components {
  width: 100%;
  height: 50%;
  overflow: hidden;
  overflow-y: auto;
  .top_one {
    display: flex;
    justify-content: space-between;
  }
  .top_two {
    padding: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #1a1a1b;
  }
}
</style>
<style lang="scss">
.group-detail-popover-box {
  .more-item {
    text-align: left;
    margin: 10px 0;
    cursor: pointer;
    img {
      width: 18px;
      height: 18px;
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
      margin-left: 10px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #222222;
      &:hover {
        color: blue;
      }
    }
  }
}
.group-page-popover {
  width: 120px !important;
  min-width: 50px !important;
  padding: 5px;
  .user-item {
    user-select: none;
    text-align: center;
    margin: 10px 0;
    cursor: pointer;
    &:hover {
      color: #0084ff;
    }
  }
}
</style>
