<template>
  <div>
    <div class="single" v-for="(item, i) in memberList" :key="i">
      <img
        v-if="dataChange(item).avatar"
        :src="dataChange(item).avatar"
        alt=""
      />
      <img
        v-else
        width="30px"
        height="30px"
        :src="require('@/assets/imgs/mrtx.png')"
        alt=""
      />

      <div :class="dataChange(item).id == selectId ? 'select-id' : ''">
        <div>{{ dataChange(item).name }}</div>
        <div>{{ dataChange(item).id }}</div>
      </div>
      <el-popover
        placement="right"
        width="120"
        trigger="click"
        popper-class="group-page-popover"
      >
        <div class="group-detail-popover-box">
          <div
            class="more-item"
            @click="$router.push(`/user/detail/${dataChange(item).id}`)"
          >
            <span>成员信息</span>
          </div>

          <div class="more-item" @click="selectFun(dataChange(item).id)">
            <span>成员发言</span>
          </div>
        </div>
        <img
          width="25px"
          height="25px"
          slot="reference"
          src="@/assets/imgs/more.png"
          alt=""
        />
      </el-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BusinessCard',
  props: {
    memberList: Array
  },
  data() {
    return {
      selectId: ''
    }
  },
  methods: {
    dataChange(item) {
      if (!item) return {}
      if (!item.user) return {}
      return item.user
    },
    selectFun(id) {
      this.selectId = id
      this.$emit('reciverBusiness', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.single {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
  border-bottom: 1px solid #f2f4f8;
  font-size: 12px;
  color: #121111;
  .select-id {
    color: rgb(62, 105, 245);
  }
}
</style>
